<template>
  <div>

      <header class="header">
          <div class="headerleft">
            <a href="https://www.mzy1203.com" title="马大侠的博客" class="header__logo">  
                <img src="@/assets/head.png"   alt="马大侠的博客">
            </a>
            <span class="span1">
              M D X 
            </span>
            <div class="fn__none header__menu"   >
                <i class="el-icon-more" @click="show = !show"></i>
            </div>
            <el-collapse-transition>
            <div class="header__links fn__flex-center" v-show="show">
                <a href="#/blogs"   class="fn__flex-center" @click="gomain()" >
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                    <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                  </svg></span>
                </a>
                <a href="javascript:void(0);" @click="isAdmin()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                    <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                  </svg>
                </a>
                <a href="https://www.mzy1203.com/download/"   >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud" viewBox="0 0 16 16">
                    <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                  </svg></a>
                <a href="#/message" @click="gomessage()"  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right-text" viewBox="0 0 16 16">
                    <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z"/>
                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                  </svg></a>
                <!-- 评论审核界面 -->
                <a href="javascript:void(0);" v-if="isCheckAdmin" @click="toCheckMessage()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                    </svg>
                </a>
                    <!-- 登录操作 -->
                <a href="#/loginandregister"  v-show="!hasLogin" @click="gologin()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-badge-fill" viewBox="0 0 16 16">
                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z"/>
                  </svg></a>
                   <!-- 退出操作 -->
                <a href="javascript:void(0);" @click="logout()" v-show="hasLogin" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-x-fill" viewBox="0 0 16 16">
             <path fill-rule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6.146-2.854a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"/>
                </svg></a>
                
                
            </div>
            </el-collapse-transition>
            </div>
         

      </header>


  </div>
</template>

<script>
export default {
    name:"BlogHeader",
    data(){
        return {
            isCheckAdmin:false,
            hasLogin:true,
            user_id:'',
            username:"",
            show: true
        }
    }, 
    created(){
        this.userIsLogin()
        this.checkTheUser()
    },
    methods: {
        // 验证用户是否登录
        userIsLogin(){
        let user=localStorage.getItem("userInfo");
         if(user!=null){
             this.hasLogin=true
             let userInfo=JSON.parse(user)
             this.user_id=userInfo.id
             this.username=userInfo.username
         }
         else{
             this.hasLogin=false
         }
    },
    checkTheUser(){
        this.$axios.post('/user/checkTheUser').
        then(res =>{
            this.isCheckAdmin = true
        }).catch(err =>{
            // console.log(err)
        })
    },
    toCheckMessage(){
        this.$axios.post('/user/checkTheUser').
        then(res =>{
            this.$router.push("/commentModeration")
        }).catch(err =>{
            this.$message({
                   showClose: true,
                   type: "warning",
                   message: "没有权限",
                  });
        })
    },
    isAdmin(){
        this.$axios.post('/user/checkTheUser').
        then(res =>{
            this.$router.push("/blogedit/add")
        }).catch(err =>{
            this.$message({
                   showClose: true,
                   type: "warning",
                   message: "没有权限",
                  });
        })
    },

    gomain(){
        this.$router.push("/blogs")
    },
    gologin(){
        this.$router.push("/loginandregister")
    },
    gomessage(){
        this.$router.push("/message")
    },

    logout(){
        localStorage.clear();
        this.$store.commit("REMOVE_INFO")
        const _this=this
        this.$axios.post('/logout')
        .then(res => {
            // console.log(res)
             if (res.status==200) {
                   this.$message({
                   showClose: true,
                   type: "success",
                   message: "已成功退出",
                  });
                  }
            this.userIsLogin()      
            _this.$router.push("/blogs")
        })
        .catch(err => {
            console.error(err); 
        })
        
    },


    },
   
}


</script>

<style scoped>

.header{
    top: 0;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    height: 70px;
    line-height: 30px;
    background-color: rgba(199,203,210);
    z-index: 15;
    
}
.headerleft{
    display: flex;
    align-items: center;
    height: 70px;
    max-width: 1203px;
    padding: 0 10px;
    margin:0 auto;
   
}
.span1{
    margin-left: 60px;
    font-size: 20px;
    font-weight: 500;
    transition: all .25s linear
}

.header__menu {
        display: none;
        margin-left: auto;
        left:90px;
    }

.header__logo {
    position: relative;
    display: inline-block;
}

.header__logo:hover {
    text-decoration: none
}


.header__logo h1:hover {
    color: #222
}

.header__logo img {
    position: absolute;
    width: 40px;
    height: 40px;
    top: -20px;
    right: -50px
}

.header__links {
    float: right;
    margin-left: auto;
    left:90px;
}

.header__links img, .header__links svg {
    height: 16px;
    width: 16px;
    margin-right: 5px
}

.header__links a {
    color: #888;
    font-size: 16px;
    margin-left: 35px
}

.header__links a:hover {
    color: #222;
    text-decoration: none
}

.fn__flex {
    display: flex
}

.fn__flex-center {
    display: inline-flex;
    align-items: center
}


 @media(max-width:768px) { 

    .header__menu {
        display: block;
        float: right
    }

    .header__menu svg {
        height: 24px;
        width: 24px;
        margin-top: 3px
    }

    .header__nav a {
        padding: 6px 12px
    }

    .header__links {
        display: -webkit-inline-box;
        position: fixed;
        top: 70px;
        background: rgba(255, 255, 255, .8);
        width: 100%;
        left: 0;
        box-sizing: border-box;
        padding: 6px 12px
    }

    .header__links a {
        display: block;
        background-color: rgba(196, 196, 196, .4);
        padding: 6px;
        margin: 6px 0
    }

    
} 
</style>